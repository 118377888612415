<template>
  <v-app class="v-app_wrapper">
    <div class="app-container">
      <HelloHeader  v-if="!iframe"/>
      <HelloMain />
    </div>
    <HelloFooter v-if="!iframe" />
  </v-app>
</template>

<script>
import HelloHeader from './components/HelloHeader.vue'
import HelloMain from './components/HelloMain.vue'
import HelloFooter from './components/HelloFooter.vue'

import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  name: 'App',
  components: {    
    HelloHeader,
    HelloMain,
    HelloFooter,
  },
  created() {
    this.getVars();
    this.getUrlParams();
  },
  computed: {
    ...mapState([
      'vars',
      'iframe',
      'utmParams'
    ]),
  },
  methods: {
    ...mapActions([
      'getVars',
      'getPrescoringData'
    ]),
    ...mapMutations([
      'SET_LEAD_ID',
      'SET_UTM_PARAMS',
      'SET_IFRAME',
      'SET_ACTIVE_TAB',
      'SET_PRODUCTO',
      'SET_DEBUG',
      'SET_CCAA',
      'SET_ORIGEN',
      'SET_PARENT'
    ]),
    getUTMParams(){
      const urlParams = new URLSearchParams(window.location.search);
        return {
          utm_source: urlParams.get('utm_source'),
          utm_medium: urlParams.get('utm_medium'),
          utm_campaign: urlParams.get('utm_campaign'),
          utm_term: urlParams.get('utm_term'),
          utm_content: urlParams.get('utm_content'),
      };
    },
    getUrlParams(){
      const urlParams = new URLSearchParams(window.location.search);
      // URL PARAMS
      const utmParams = this.getUTMParams();
      this.SET_UTM_PARAMS(utmParams);
      // LEAD ID
      const leadId = urlParams.get('lead_id');
      if (leadId){
        this.SET_LEAD_ID(leadId);
        this.getPrescoringData();
      }else{
        this.SET_ACTIVE_TAB('InitialView');
      }
      // Producto
      const producto = urlParams.get('p');
      if (producto){
        this.SET_PRODUCTO(producto);
      }
      // IFRAME
      const iframe = urlParams.get('iframe');
      if (iframe){
        this.SET_IFRAME(iframe);
      }
      // Debug
      const debug = urlParams.get('debug');
      if (debug){
        this.SET_DEBUG(true);
      }
      // CCAA
      const ccaa = urlParams.get('ccaa');
      if (ccaa){
        this.SET_CCAA(ccaa);
      }
      // origen
      const source = urlParams.get('source');
      if (source){
        this.SET_ORIGEN(source);
      }
      // id_parent
      const id_parent = urlParams.get('id_parent');
      if (id_parent){
        this.SET_PARENT(id_parent);
      }
    }
  }
}
</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap");

  /* Global styles */
  :root {
    --helloteca-primary: #25CCD5;
    --helloteca-secundary: #5A4FA2;
  }
  body{
    font-family: "Space Grotesk", sans-serif;
    color: var(--helloteca-secundary);
    margin: 0;
    font-weight: 400;
  }
  .v-app_wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  } 
  .app-container  {
    flex: 1;
    padding: 24px;   
    /* imágenes: izqda superior /dcha superior / puntos blancos  /  puntos oscuros / izqda inferior  /  chica*/ 
    background:
              url(./assets/background/Vector_5.png),
              linear-gradient(133deg, #67C9D3 27.89%, #CDF8FB 100%);
    background-repeat: no-repeat;
    background-position: 0 0,0 0;
    background-size: 185px, 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @media (min-width:750px){
    .app-container  {
      background-image:
                      url(./assets/background/Vector_5.png),url(./assets/background/Vector_4.png),url(./assets/background/little_points.png),
                      url(./assets/background/points.png),url(./assets/background/Vector_1.png),
                      linear-gradient(90deg, #67C9D3 27.89%, #CDF8FB 100%);
      background-repeat: no-repeat;
      background-position: 0 0, 100% 0, 73% 22%, 88% 18%,0 100%, 0 0;
      background-size: 300px 120px, 330px 220px, 55px, 84px, 558px, 100%;
    }
  } 
  @media (min-width:1024px){
    .app-container  {
      background-image:
                      url(./assets/background/Vector_5.png),url(./assets/background/Vector_4.png),url(./assets/background/little_points.png),
                      url(./assets/background/points.png),url(./assets/background/Vector_1.png),url(./assets/background/chica_simulador.png),
                      linear-gradient(90deg, #67C9D3 27.89%, #CDF8FB 100%);
      background-repeat: no-repeat;
      background-position: 0 0, 100% 0, 73% 22%, 88% 18%,0 100%, 90% 100%, 0 0;
      background-size: 300px 120px, 330px 220px, 55px, 84px, 10%, 25%, 100%;
    }
  }
  
</style>
